<template>
  <div class="login">
    <div class="loginCenter">
      <div class="longinTitle">视频辅助教学系统</div>
      <div class="loginTips">
        <p>用户登录</p>
        <span>新用户注册</span>
      </div>
      <div class="loginMain">
        <el-input placeholder="请输入内容" prefix-icon="el-icon-s-custom" v-model="userName" clearable>
        </el-input>
        <el-input placeholder="请输入内容" prefix-icon="el-icon-lock" v-model="password" show-password clearable>
        </el-input>
        <van-button type="info" @click="login">登录</van-button>
      </div>
      <div class="loginBot">
        <el-checkbox v-model="checked">记住登录状态</el-checkbox>
        <span>忘记密码？</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  data() {
    return {
      userName: "",
      password: "",
      checked: false,
    };
  },
  methods: {
    login() {
      let para = {
        LoginName: this.userName,
        LoginPassWord: this.password,
      };
      this.$axios.post('api/Student/Login', para).then(res => {
        let data = JSON.parse(res.data);
        if (data.code == 200) {
          Notify({ type: 'success', message: '登录成功！' });
          this.cookies.kkSet("userId", data.data.ID);
          this.$router.push({ name: "project" });
        } else {
          Notify({ type: 'danger', message: data.info });
        }
      });
    },
  },
  mounted() { },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.loginCenter {
  width: 350px;
  height: 500px;
  margin: 0 auto;
}
.login .longinTitle {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.login .loginTips {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 20px;
}
.login .loginTips p {
  font-weight: bold;
  font-size: 16px;
}
.login .loginTips span {
  color: #1891ff;
  font-size: 14px;
}
.login .loginMain /deep/ .el-input {
  font-size: 16px;
}
.login .loginMain /deep/ .el-input .el-input__clear {
  font-size: 16px;
}
.login .loginMain /deep/ .el-input__inner {
  height: 50px;
  margin-bottom: 20px;
  line-height: 50px;
}
.login .loginMain /deep/ .el-input__icon {
  height: 50px;
  line-height: 50px;
}
.login .loginMain /deep/ .van-button {
  width: 100%;
  border-radius: 5px;
}
.login .loginBot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.loginBot /deep/ .el-checkbox {
  color: #aaa !important;
}
.login .loginBot span {
  color: #1891ff;
  font-size: 14px;
}
</style>
